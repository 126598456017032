<template>
    <div class="bg-white">
        <div class="col-12 d-md-flex p-0 mt-4">
            <div class="col-md-2">
                <label for="">Tư vấn viên</label>
                <input type="text" class="form-control" v-model="query.keyword" @input="search" placeholder="Nhập mã, tên, sđt">
            </div>
            <div class="col-md-2">
                <label for="">Văn phòng làm việc</label>
                <el-select class="w-100" v-model="query.branch_id" filterable @change="search" clearable  placeholder="Chọn văn phòng">
                    <el-option v-for="item in listBranch" :key="item.id"
                               :label="item.name_alias ?? item.name"
                               :value="item.id">
                        <span style="float: left">{{ item.name_alias ?? item.name }}</span>
                    </el-option>
                </el-select>
            </div>
            <div class="col-md-2">
                <label for="">Trạng thái học</label>
                <el-select class="w-100" v-model="query.status" @change="search" clearable multiple collapse-tags placeholder="Chọn trạng thái học">
                    <el-option v-for="item in listStatus" :key="item.id" :label="item.name" :value="item.id">
                        <span style="float: left">{{ item.name }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="bg-white">
            <div class="example-preview table-responsive overflow-auto mt-3">
                <table class="table text-left table-hover table-bordered table-vertical-center b-table">
                    <thead>
                        <tr>
                            <th>Mã tư vấn viên</th>
                            <th>Tên tư vấn viên</th>
                            <th>Số điện thoại</th>
                            <th>Văn phòng</th>
                            <th>Thời gian thêm tư vấn viên</th>
                            <th>Trạng thái học</th>
                            <th>Thời hạn học đào tạo</th>
                        </tr>
                    </thead>
                    <tbody v-loading="loading">
                        <tr v-for="(item, index) in data" :key="index">
                            <td>{{ item.user ? item.user.id + 100000 : '' }}</td>
                            <td>{{ item.user ? item.user.name : '' }}</td>
                            <td>{{ item.user ? item.user.phone : '' }}</td>
                            <td>{{ item.profiles && item.profiles.branch ? (item.profiles.branch.name_alias ?? item.profiles.branch.name) : '' }}</td>
                            <td>{{ item.created_at |formatDateTime}}</td>
                            <td>
                                <span class="rounded-circle px-2 mr-2 " :class="changeStatus(item.status_course).color"></span>
                                {{ changeStatus(item.status_course).text }}
                            </td>
                            <td>
                                <span class="bold p-2 rounded"
                                      :class="deadlineCandidate(item.disableTime ? item.disableTime : '', item.status_course).bg">
                                    {{ deadlineCandidate(item.disableTime, item.status_course).data }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end mr-2">
                    Tổng số bản ghi: {{total}}
                </div>
                <div class="edu-paginate mx-auto  mt-3 d-flex justify-content-center">
                    <paginate
                        v-model="page"
                        :page-count="paging.last_page"
                        :page-range="3"
                        :margin-pages="1"
                        :click-handler="clickCallback"
                        :prev-text="'Trước'"
                        :next-text="'Sau'"
                        :container-class="'pagination b-pagination'"
                        :pageLinkClass="'page-link'"
                        :next-link-class="'next-link-item'"
                        :prev-link-class="'prev-link-item'"
                        :prev-class="'page-link'"
                        :next-class="'page-link'"
                        :page-class="'page-item'"
                    >
                    </paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_TVV_BY_COURSE} from "../../../core/services/store/user/users.module";
import { GET_BRANCHES_ALL } from "../../../core/services/store/center/branch.module";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";

export default {
    name: "TrainingManage",
    data() {
        return {
            data: [],
            paging: {
                current_page: 1,
                per_page: 10,
                total: 0,
                link: {},
                total_pages: '',
                last_page: 1
            },
            page: 1,
            query: {
                keyword: '',
                branch_id: '',
                status: '',
            },
            listBranch: {},
            listStatus: [
                {
                    id: 0,
                    name: 'Chưa học'
                },
                {
                    id: 1,
                    name: 'Đang học'
                },
                {
                    id: 2,
                    name: 'Đã học'
                },
                {
                    id: 3,
                    name: 'Hoàn thành'
                },
            ],
            total: 0,
            loading: true
        }
    },
    mounted() {
        this.getdata();
        this.getAllBranch();
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Chương trình", icon: 'far fa-sitemap' },
            { title: "Chương trình đào tạo TVV định kỳ" , icon: 'far fa-chart-bar' },
        ]);
    },
    computed: {
      ...mapGetters(['currentUser'])
    },
    methods: {
        getAllBranch() {
            this.$store.dispatch(GET_BRANCHES_ALL, {area_id: this.currentUser.profile.khuvuc_id}).then((response) => {
                this.listBranch = response.data.data;
            })
        },
        clickCallback(obj) {
            this.loading = true;
            this.page = obj;
            this.pushParamsUrl();
            this.getdata()
        },
        pushParamsUrl() {
            let query = {
                page: this.page,
                ...this.query
            }
            this.$router.push({
                name: 'training-manage',
                query: query
            })
        },
        search() {
            this.loading = true;
            this.page = 1;
            this.pushParamsUrl();
            this.getdata()
        },
        changeStatus(status) {
            let text;
            let color;
            switch (status) {
                case 0:
                    text = 'Chưa học';
                    color = 'bg-secondary';
                    break;
                case 1:
                    text = 'Đang học';
                    color = 'bg-orange';
                    break;
                case 2:
                    text = 'Đã học';
                    color = 'bg-blue';
                    break;
                case 3:
                    text = 'Hoàn thành';
                    color = 'bg-green';
                    break;
            }
            return { text: text, color: color };
        },
        getdata() {
            this.loading = true;
            this.$store.dispatch(GET_TVV_BY_COURSE, {
                page: this.page,
                ...this.query
            }).then((data) => {
                this.data = data.data.data;
                this.paging.total = data.data.total;
                this.paging.last_page = data.data.last_page;
                this.paging.per_page = data.data.per_page;
                this.paging.current_page = data.data.current_page;
                this.total = data.data.total;
                this.loading = false;
            });
        },
        deadlineCandidate(deadline, status) {
            let now = this.$moment().format('YYYY-MM-DD');;
            let bg = '';
            let deadline_moment = this.$moment(deadline);
            let between = deadline_moment.diff(now);
            let data = 'Còn ' + deadline_moment.diff(now, 'days') + ' ngày';
            if (deadline_moment.diff(now, 'days') <= 0 || status  == 3) {
              data = '';
              bg = '';
            } else {
              bg = 'bg-pink';
            }
            if (between !== between) {
              data = 'Không có thông tin .';
              bg = 'bg-pink';
            }
            this.bgDeadline = 'bg-pink';
            return {
              data: data,
              bg: bg
            };
        },
    }
}
</script>

<style scoped>
.bg-pink {
    background: pink;
}
.bg-orange {
    background: orange;
}
.bg-blue {
    background: dodgerblue;
}
.bg-green {
    background: green;
}
</style>